import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "command-names-and-keys"
    }}>{`Command Names and Keys`}</h1>
    <h2 {...{
      "id": "general-guidelines"
    }}>{`General Guidelines`}</h2>
    <p>{`The names of commands and options (subsets of commands) appear in the same font as the surrounding
text, but with an initial capital letter. Do not use command or option names as verbs or
possessives.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`If you’re looking at photos on the web, use the Zoom command to get a closer look. If you see your
boss coming down the hall while you’re looking at the photos, use the Alt+F4 command to quickly
close the window.`}</li>
      </ul>
    </Do>
    <h2 {...{
      "id": "responsive-web"
    }}>{`Responsive Web`}</h2>
    <p>{`It’s also important to take into account that the Web experience can be from a keyboard/mouse or
touch device. It’s important to differentiate among choose, enter, select, specify, and type. Do not
use pick or hit, and avoid using click.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`To see your messages, use the Inbox tab.`}</li>
        <li parentName="ul">{`Select Print from the File menu.`}</li>
      </ul>
    </Do>
    <h2 {...{
      "id": "native-mobile"
    }}>{`Native Mobile`}</h2>
    <p>{`For mobile instructions, the following terms can be used: swipe, scroll, tap, double-tap, drag,
pinch, zoom, and select.`}</p>
    <h2 {...{
      "id": "keys"
    }}>{`Keys`}</h2>
    <p>{`Name keys by what’s marked on them. To indicate when the customer needs to press two or more keys at
the same time, use plus signs and no extra spaces between the names:`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Press Ctrl+Alt+Del.`}</li>
        <li parentName="ul">{`Key names include Shift, Ctrl, Alt, Enter (not Return), Tab, Backspace, Del, Esc, F1, Left Arrow,
Right Arrow, Up Arrow, Down Arrow. Note that spacebar is not capitalized.`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      